import {Http} from './axios'
import {ResponseWrapper} from "@/utils/wrappers/response";
import {ErrorWrapper} from "@/utils/wrappers/error";

export class ArticlesService {
  static get entity() {
    return 'articles'
  }
  /**
   * ------------------------------
   * @HELPERS
   * ------------------------------
   */

  static request(status:{auth:boolean} = { auth: false }) {
    const axiosInstance = new Http(status)
    return  axiosInstance.initRequestFetching();
  }

  /**
   * ------------------------------
   * @API_CALLS_PUBLIC
   * ------------------------------
   */

  static async getAllArticles() {
    try {
      const response = await this.request().get(`/${this.entity}/`)
      //console.log(response);
      const data = {
        content: response.data.reverse(),
        total: Number(response.data.length)
      }
      return new ResponseWrapper(response, data)
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
  static async getArticlesCategoriesList() {
    try {
      const response = await this.request().get('/article-category/')
      //console.log(response);
      const data = {
        content: response.data,
        total: Number(response.data.length)
      }
      return new ResponseWrapper(response, data)
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
  static async getOnHeadlineArticles() {
    try {
      const response = await this.request().get(`/${this.entity}/head-line`)
      //console.log(response);
      const data = {
        content: response.data.reverse(),
        total: Number(response.data.length)
      }
      return new ResponseWrapper(response, data)
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
  static async getArticleById(id: number) {
    try {
      const response = await this.request().get(`/${this.entity}/${id}`)
      return new ResponseWrapper(response, response.data)
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async searchArticle(title: string) {
    try {
      console.log(title)
      const response = await this.request().get(`/${this.entity}/search/${title}`)
      console.log(response)
      return new ResponseWrapper(response, response.data)
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  /**
   * ------------------------------
   * @API_CALLS_PRIVATE
   * ------------------------------
   */

  static async create(data = {}) {

    try {
      const response = await this.request({ auth: true }).post('/articles/', data)
      //console.log(response)
      return new ResponseWrapper(response, response.data)
    } catch (error: any) {
      throw new ErrorWrapper(error)
    }
  }
  static async createBulk(data: any) {
    //assert.object(data, { required: true })
    console.log('inside Bulk');

    try {
      const response = await this.request({ auth: true }).post('/articles/bulk', data)
      //console.log(response)
      return new ResponseWrapper(response, response.data)
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }


  static async update(id: number, data: any = {}) {

    try {
      const response = await this.request({ auth: true }).put(`/articles/${id}`, data)
      return new ResponseWrapper(response, response.data)
    } catch (error: any) {
      throw new ErrorWrapper(error)
    }
  }

  static async remove(id: number) {

    try {
      const response = await this.request({ auth: true }).delete(`${this.entity}/${id}`)
      return new ResponseWrapper(response, response.data.data)
    } catch (error: any) {
      throw new ErrorWrapper(error)
    }
  }
}
