import {ActionTree} from "vuex";
import {AdsState} from "@/utils/interfaces/ads.interface";
import {AdsService} from "@/services/ads.service";

export const adsActions: ActionTree<AdsState, AdsState> = {

  async getAllActiveAdsAction({commit}) {
    try {
      const response = await AdsService.getAllActiveAds()
      console.log(response)
      commit('SET_ALL_ACTIVE_ADS', response.data)
      return true
    } catch (e) {
      return false;
    }
  },
}
