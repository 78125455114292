//import {VUE_APP_API_URL} from '@/.env';
import {Http} from './axios'
import {ResponseWrapper} from "@/utils/wrappers/response";
import {ErrorWrapper} from "@/utils/wrappers/error";
import $router from '@/router'


export class AuthService {
  static get entity() {
    return 'auth'
  }

  static request(status = {auth: false}) {
    const axiosInstance = new Http(status)
    const myInstance = axiosInstance.initRequestFetching()
    console.log('INSTANCE : ', myInstance)
    return myInstance;
  }

  static async makeLogin(payload: { email: string, password: string }) {
    try {
      const response = await AuthService.request().post(`/${this.entity}/login`, payload)

      _setAuthData({
        accessToken: response.data.tokens.access.token,
        exp: response.data.tokens.access.expires
      })

      _setCurrentUser(response.data.user)
      AuthService.setRefreshToken(response.data.tokens.refresh.token)
      AuthService.setAccessToken(response.data.tokens.access.token)

      return new ResponseWrapper(response, response.data)
    } catch (error: any) {
      console.log(error);
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message || 'Unknown Error';
        throw new ErrorWrapper(error, errorMessage);
      } else {
        // Handle cases where error.response or error.response.data is missing
        throw new ErrorWrapper(error, 'Unknown Error');
      }
    }
  }

  static async makeLoginWithFacebook({accessToken, inputToken}: { accessToken: string, inputToken: string }) {
    try {
      const response = await this.request().post(`/auth/facebook-login`, {
        'accessToken': accessToken,
        'inputToken': inputToken
      } /*, { withCredentials: true } */)
      _setAuthData({
        accessToken: response.data.tokens.access.token,
        exp: response.data.tokens.access.expires

      })
      _setCurrentUser(response.data.user)

      AuthService.setRefreshToken(response.data.tokens.refresh.token)
      AuthService.setAccessToken(response.data.tokens.access.token)

      // AuthService.setRefreshToken(true)

      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async makeLoginWithGoogle({idToken}: { idToken: string }) {
    try {
      const response = await this.request().post(`/${this.entity}/google-login`, {'id_token': idToken} /*, { withCredentials: true } */)
      _setAuthData({
        accessToken: response.data.tokens.access.token,
        exp: response.data.tokens.access.expires

      })
      _setCurrentUser(response.data.user)

      AuthService.setRefreshToken(response.data.tokens.refresh.token)
      AuthService.setAccessToken(response.data.tokens.access.token)

      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async makePasswordResetRequest({email}: { email: string }) {
    try {
      const response = await this.request().post(`/${this.entity}/ask-password-reset`, {'email': email} /*, { withCredentials: true } */)

      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async makeResetPassword({password, resetToken}: { password: string, resetToken: string }) {
    try {
      const response = await this.request().post(`/${this.entity}/reset-password/${resetToken}`, {'newPassword': password} /*, { withCredentials: true } */)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async makeUpdatePassword({oldPassword, newPassword}: { oldPassword: string, newPassword: string }) {
    try {

      const response = await this.request({auth: true}).post(`/${this.entity}/online-reset-password`, {
        'newPassword': newPassword,
        'oldPassword': oldPassword
      } /*, { withCredentials: true } */)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }


  static async makeActivateAccount({token}: { token: string }) {
    try {
      const response = await this.request().post(`/${this.entity}/verify-email?token=${token}` /*, { withCredentials: true } */)

      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async makeRegistration(queryBody: any) {
    try {
      const response = await this.request().post(`/${this.entity}/register`, queryBody)

      $router.push({name: 'login'})
        .catch((e) => {
        console.log(e)
      })
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async makeUpdateProfile (queryBody: any) {
    try {
      const response = await this.request({ auth: true }).put(`/${this.entity}/profile`, queryBody)
      console.log(response)
      _setCurrentUser(response.data)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  /*static async makeUpdateProfile({ role, queryBody }:{ role:string, queryBody:any }) {
      //console.log(queryBody)
      try {
          var prefix = ''
          delete queryBody.email;
          if (role === 1) {
              prefix = '/student'
              delete queryBody.teacherDescription
          }

          if (role === 2) {
              prefix = '/teacher'
              queryBody = {
                  "teacherDescription": queryBody.teacherDescription,
                  "profilePicture": queryBody.profilePicture,
                  "ProfileIdentity": {
                      "firstname": queryBody.ProfileIdentity.firstname,
                      "lastname": queryBody.ProfileIdentity.lastname,
                      "phone": queryBody.ProfileIdentity.phone
                  }
              }
          }

          if (role === 3) {
              prefix = '/admin'
              delete queryBody.teacherDescription
              delete queryBody.establishment
              delete queryBody.faculty
              delete queryBody.level
              queryBody = {
                  "profilePicture": queryBody.profilePicture,
                  "ProfileIdentity": {
                      "firstname": queryBody.ProfileIdentity.firstname,
                      "lastname": queryBody.ProfileIdentity.lastname,
                      "phone": queryBody.ProfileIdentity.phone
                  }
              }
          }

          if (queryBody.profilePicture === "" || queryBody.profilePicture === null) {
              delete queryBody.profilePicture;
          }
          const response = await this.request({ auth: true }).put(`/profile${prefix}`, queryBody)

          _setCurrentUserProfile(response.data, role);

          return new ResponseWrapper(response, response.data)
      } catch (error) {

          throw new ErrorWrapper(error)
      }
  }*/

  /*static async makeUploadProfilePicture(profileUuid, profilePicture) {
      try {
          let formData = new FormData()
          formData.append('profilePicture', profilePicture)
          const response = await this.request({ auth: true }).post(`/auth/upload/profile-picture/${profileUuid}`, formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          })
          return new ResponseWrapper(response, response.data)
      } catch (error:any) {
          const message = error.response.data ? error.response.data.message : error.response.statusText
          throw new ErrorWrapper(error, message)
      }
  }*/

  /*static async makeRegistrationWithFacebook({ accessToken, inputToken }) {
      try {

          const response = await this.request().post(`/auth/facebook-register`, { 'accessToken': accessToken, 'inputToken': inputToken } )
          _setAuthData({
              accessToken: response.data.tokens.access.token,
              exp: response.data.tokens.access.expires
              // exp: _parseTokenData(response.data.tokens.access.expires)
          })
          _setCurrentUser(response.data.user)

          AuthService.setRefreshToken(response.data.tokens.refresh.token)
          AuthService.setAccessToken(response.data.tokens.access.token)

          $router.push({ name: 'login' }).catch(() => { })
          return new ResponseWrapper(response, response.data.data)
      } catch (error) {
          throw new ErrorWrapper(error)
      }
  }*/

  static async makeRegistrationWithGoogle({idToken}: { idToken: string }) {
    try {

      const response = await this.request().post(`/${this.entity}/google-register`, {'id_token': idToken} /*, { withCredentials: true } */)
      _setAuthData({
        accessToken: response.data.tokens.access.token,
        exp: response.data.tokens.access.expires

      })
      _setCurrentUser(response.data.user)

      AuthService.setRefreshToken(response.data.tokens.refresh.token)
      AuthService.setAccessToken(response.data.tokens.access.token)

      //$router.push({ name: 'login' }).catch(() => { })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async makeLogout() {
    try {
      _resetAuthData()
      await $router.push({name: 'login'})
      return new ResponseWrapper({'data': 'success'}, 'sucesss')
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async refreshTokens() {
    try {
      const response = await this.request({auth: true}).post(`/${this.entity}/refresh-token`, {
        'refreshToken': localStorage.getItem('refreshToken')
      })
      _setAuthData({
        accessToken: response.data.tokens.access.token,
        exp: response.data.tokens.access.expires
      })
      AuthService.setRefreshToken(response.data.tokens.refresh.token)
      AuthService.setAccessToken(response.data.tokens.access.token);
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      _resetAuthData();
      // $router.push({ name: 'login' })/*.catch(() => { })*/
      //throw new ErrorWrapper(error)
    }
  }

  static async makeResendActivationMail({email}: { email: string }) {
    try {

      const response = await this.request().post(`/auth/resend-verification-email`, {'email': email} /*, { withCredentials: true } */)

      return new ResponseWrapper(response, response.data)
    } catch (error: any) {

      throw new ErrorWrapper(error, error.response.data.message)
    }
  }


  /**
   ******************************
   * @METHODS
   ******************************
   */

  static async getCurrentUser() {
    try {
      const response = JSON.parse(String(localStorage.getItem('currentUser')))
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(response);
        }, 100);
      });
      //return response
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static isAccessTokenExpired() {
    const expireAccesTokenTime: string = localStorage.getItem('expireAccesTokenTime') ?? ''
    const accessTokenExpDate = (new Date(expireAccesTokenTime)).getTime() / 1000
    const nowTime = Math.floor(new Date().getTime() / 1000)

    return accessTokenExpDate <= nowTime
  }

  static hasRefreshToken() {
    return Boolean(localStorage.getItem('refreshToken'))
  }

  static setRefreshToken(token: string) {
    localStorage.setItem('refreshToken', token)
  }

  static setAccessToken(token: string) {
    localStorage.setItem('accessToken', token)
  }

  static getBearer() {

    const token = localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : null;

    return `Bearer ${token}`

  }


}

/**
 ******************************
 * @private_methods
 ******************************
 */

function _parseTokenData(accessToken: string) {
  let payload = ''
  let tokenData = {}

  try {
    payload = accessToken.split('.')[1]
    tokenData = JSON.parse(atob(payload))
  } catch (error: any) {
    throw new Error(error)
  }

  return tokenData
}

function _resetAuthData() {
  // reset tokens
  localStorage.removeItem('currentUser')
  localStorage.removeItem('expireAccesTokenTime')
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
}

function _setAuthData({accessToken, exp}: { accessToken: string, exp: string }) {
  localStorage.setItem('expireAccesTokenTime', exp)
}

function _setCurrentUser(user: any) {
  localStorage.setItem('currentUser', JSON.stringify(user))
}

